import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import useScrollPosition from '@react-hook/window-scroll'
import clsx from 'clsx'

// import app components
import DesktopMenu from './menu/DesktopMenu'
import mascot_logo from '../images/the_mascot_logo.svg'

const Header = (props) => {
  const {
    location: { pathname },
  } = props

  const scrollY = useScrollPosition()

  const [containerClasses, setContainerClasses] = useState('');

  const isHidden = typeof window !== "undefined" && scrollY > 300
  const isSticky = typeof window !== "undefined" && scrollY > 400
  const isHomePage = pathname === "/"

  useEffect(() => {
    setContainerClasses(clsx(
      isHidden && 'is-hidden',
      isSticky && 'is-sticky',
      isHomePage && 'is-homepage'
    ));
  }, [pathname, scrollY]);

  return (
    <div className={`header-container w-full px-2 md:px-0 z-50 top-0 ${containerClasses}`} >
      <div className="mx-auto max-w-screen-xl flex justify-center align-center flex-wrap">
        <div className="header-logo-container w-full text-center px-4 md:px-0 pt-6 lg:pt-10 lg:pb-2">
          <Link to="/" className="flex items-center justify-center">
            <img
              className="mascot-logo"
              src={mascot_logo}
              alt="The Mascot"
            />
          </Link>
        </div>
        <div className="desktop-menu-container w-full max-w-screen-lg my-1 md:my-2">
          <DesktopMenu
            pathname={pathname}
            middleItem={
              <div className="desktop-menu-logo w-full my-auto flex justify-center">
                <Link to="/" className="flex justify-center">
                  <img
                    className="mascot-logo"
                    src={mascot_logo}
                    alt="The Mascot"
                  />
                </Link>
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Header
