import React from 'react'
import {Link} from 'gatsby'

import FooterMenu from './menu/FooterMenu'
import mascot_logo from '../images/mascot-logo.svg'

const Footer = props => {
  return (
    <div className="w-full bg-primary py-8 px-8">
      <div className="mx-auto max-w-screen-xl text-white">
        <div className=" ">
          <Link to="/">
            <img
              className="mascot-logo w-64 mb-2 mx-auto"
              src={mascot_logo}
              alt="The Mascot"
            />
          </Link>
        </div>
        <div className="border-t border-white flex justify-center">
          <FooterMenu className="footer-menu mt-1" {...props} />
        </div>
      </div>
    </div>
  )
}

export default Footer
