import React from 'react'
import { Link } from 'gatsby'

import { useCustomer } from 'gatsby-theme-harlan'

import instagram_logo from '../../images/instagram.svg'
import instagram_red from '../../images/instagram-red.svg'

const FooterMenu = () => {
  const { isLoggedIn, allocationStatus } = useCustomer()

  const footerLinks = [
    { url: '/faqs', text: 'FAQs' },
    { url: '/shipping-policy', text: 'Shipping Policy' },
    { url: '/privacy-and-terms', text: 'Privacy & Terms' },
    { url: 'mailto:team@mascotwine.com', text: 'team@mascotwine.com', type: 'external' },
    { url: 'tel:7079440350', text: '707.944.0350', type: 'external' },
  ]

  return (
    <div className="flex flex-row flex-wrap pt-2 lg:pt-0">
      {isLoggedIn && allocationStatus == 'offering' && (
        <MenuItem key={99} item={{ url: '/profile', text: 'Account' }} />
      )}
      {footerLinks.map((o, i) => {
        return <MenuItem key={i} item={o} />
      })}
      <a
        href="https://www.instagram.com/mascotwine/"
        target="_blank"
        rel="noopener noreferrer"
        className="instagram group flex justify-center mx-4 py-4 lg:py-2 w-full lg:w-auto"
      >
        <img className="instagram-logo block group-hover:hidden" src={instagram_logo} alt="Instagram" width="25" />
        <img className="instagram-logo-hover hidden group-hover:block" src={instagram_red} alt="Instagram" width="25" />
      </a>
    </div>
  )
}

const MenuItem = (params) => {
  const item = params.item
  return (
    <div className="flex justify-center relative w-full lg:w-auto">
      {item.type === 'external' ? (
        <a href={item.url} className="no-underline text-white mx-4 py-2 lg:py-4 hover:secondary">
          <span className="footer-link-text">{item.text}</span>
        </a>
      ) : (
        <Link active={''} to={item.url} className="no-underline text-white mx-4 py-2 lg:py-4 hover:secondary">
          <span className="footer-link-text">{item.text}</span>
        </Link>
      )}
    </div>
  )
}

export default FooterMenu
