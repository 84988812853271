import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import { useCustomer, useLogin, useAllocation } from "gatsby-theme-harlan"

const DesktopMenu = (props) => {
  const { pathname } = props;
  const { isLoggedIn, isCustomerLoading } = useCustomer()
  const { allocationStatus, isAllocationLoading } = useAllocation()

  const { logout } = useLogin()
  const [homePageLink, setHomePageLink] = useState("/")

  const isBrowser = typeof window !== "undefined"
  const isHomePage = isBrowser && window.location.pathname === "/"
  // hide links if customer is loading, or if customer has loaded, then if logged in wait for allocation
  const shouldHideLinks = isCustomerLoading || (!isCustomerLoading && isLoggedIn && isAllocationLoading)

  const offeringLink =
    allocationStatus === "offering"
      ? {
          to: "/offering", // @todo: this used to include /letter. Make sure it this route is redirecting correctly for thank-you, expired, etc.
          text: "Offering",
        }
      : {
          to: "/profile",
          text: "Account",
        }

  useEffect (() => {
    if(isHomePage && (homePageLink !== '/#story')) {
      setHomePageLink('/#story')
    }
    else if(isBrowser && !isHomePage && (homePageLink !== '/')) {
      setHomePageLink('/')
    }
  }, [pathname, isHomePage, isBrowser])

  return (
    <div className={`flex justify-between ${shouldHideLinks ? "hide-header-links" : ""}`}>
      <div className={`desktop-menu-item flex justify-center`}>
        <Link className="desktop-menu-link" to={homePageLink}>
          <span className="nav-link-text">Story</span>
        </Link>
      </div>
      <div className={`desktop-menu-item flex justify-center`}>
        <Link className="desktop-menu-link" to={"/wine"}>
          <span className="nav-link-text">Wine</span>
        </Link>
      </div>

      {props.middleItem && props.middleItem}

      {isLoggedIn ? (
        <>
          <div className={`desktop-menu-item flex justify-center`}>
            <Link className="desktop-menu-link" to={offeringLink.to}>
              <span className="nav-link-text">{offeringLink.text}</span>
            </Link>
          </div>
          <div className={`desktop-menu-item flex justify-center`} onClick={logout} >
            <span className={`desktop-menu-link`}>
              <span className="nav-link-text">Logout</span>
            </span>
          </div>
        </>
      ) : (
        <>
          <div className={`desktop-menu-item flex justify-center`}>
            <Link className="desktop-menu-link" to={"/allocation-request"}>
              <span className="nav-link-text">Join Allocation</span>
            </Link>
          </div>
          <div className={`desktop-menu-item flex justify-center`}>
            <Link className="desktop-menu-link" to={"/login"}>
              <span className="nav-link-text">Login</span>
            </Link>
          </div>
        </>
      )}
    </div>
  )
}

export default DesktopMenu
