import axios from 'axios'
import * as Sentry from '@sentry/gatsby'
import useToken from '../hooks/auth/useToken'

const useAxiosConfig = (tenantName) => {
  const { token } = useToken()

  axios.defaults.headers.common['Content-Type'] = 'application/json'

  axios.defaults.headers.common['Tenant'] = tenantName
  if (token) {
    axios.defaults.headers.common['Authorization'] = `${token}`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
  const requestInterceptor = axios.interceptors.request.use(
    (config) => {
      const url = new URL(config.url, window.location.origin)
      const bypassSentry = url.searchParams.get('bypassSentry') === 'true'
      //Added a bypassSentry search paramater in hooks to not trigger a sentry error if conditions are met, the hook returning an actual error should be handled in the responseInterceptor

      if (!bypassSentry && !config.headers.Authorization) {
        Sentry.captureMessage('Missing headers in Axios request', {
          level: 'error',
          extra: {
            tenant: config.headers['Tenant'],
            hasToken: !!config.headers.Authorization,
            url: config.url,
            headers: config.headers,
          },
        })
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response) {
        const errorData = {
          error_message: error.message,
          status: error.response.status,
          statusText: error.response.statusText,
          url: error.response.config.url,
          method: error.response.config.method,
          data: (() => {
            try {
              const requestData = JSON.parse(error.response.config.data)
              if (requestData.password) {
                requestData.password = '<redacted>'
              }
              return JSON.stringify(requestData)
            } catch (e) {
              // If parsing fails, return the original data
              return error.response.config.data
            }
          })(),
          commerce7_info: error.response.data,
          commerce7_errors: JSON.stringify(error.response.data.errors),
        }

        // validation and unauthorized error in sentry
        if (error.response.data.type === 'validationError' || error.response.data.type === 'unauthorized') {
          Sentry.withScope((scope) => {
            scope.setTag('error_type', error.response.data.type)
            scope.setExtra('error_details', errorData)
            Sentry.captureException(new Error(error.response.data.type))
          })
        } else {
          Sentry.withScope((scope) => {
            scope.setTag('service', 'axios')
            scope.setExtra('error_details', error.response.data.message)
            Sentry.captureException(error)
          })
        }
      }
      return Promise.reject(error)
    }
  )

  return () => {
    axios.interceptors.request.eject(requestInterceptor)
    axios.interceptors.response.eject(responseInterceptor)
  }
}

export default useAxiosConfig
